import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://cartql.com"
      }}>{`CartQL`}</a>{` is a GraphQL Shopping Cart API designed to work with your existing frontend or backend.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Built for the Jamstack`}</strong>{` - No more writing custom business logic to handle cart and checkout. Works with Apollo Client, URQL, fetch, and more.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Flexible cart items`}</strong>{` - Store any type of data on cart and cart items with the CartQL Mutations API`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`No replatforming`}</strong>{` - CartQL was built to handle custom cart items for SKUs, promotions, shipping, and so much more.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Bring your own inventory`}</strong>{` - Whether you're storing products in the filesystem, or in another API, such as CMS, it works with CartQL.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Bring your own frontend, or backend`}</strong>{` - No more learning new client-side libraries for each framework to manage cart state. Work with what you already use, or stitch it with other GraphQL APIs.`}</p>
      </li>
    </ul>
    <h2>{`Manage cart items, checkout and pay for orders with a simple declarative GraphQL API.`}</h2>
    <p>{`The API is completely open, and free to use. See some of the common mutations users use when building their commerce experience.`}</p>
    <p>{`To get stated, use this endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://api.cartql.com
`}</code></pre>
    <h3>{`Fetch a new or existing Cart`}</h3>
    <p>{`Run the following query, or modify it with your own `}<inlineCode parentName="p">{`id`}</inlineCode>{`, or currency code such as `}<inlineCode parentName="p">{`EUR`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`query {
  cart(id: "ck5r8d5b500003f5o2aif0v2b", currency: { code: GBP }) {
    ...CartWithItems
  }
}

fragment CartWithItems on Cart {
  ...CartInfo
  items {
    ...ItemInfo
  }
}

fragment CartInfo on Cart {
  id
  email
  isEmpty
  abandoned
  totalItems
  totalUniqueItems
  currency {
    code
    symbol
  }
  subTotal {
    amount
    formatted
  }
  shippingTotal {
    amount
    formatted
  }
  taxTotal {
    amount
    formatted
  }
  grandTotal {
    amount
    formatted
  }
  metadata
  notes
  createdAt
  updatedAt
}

fragment ItemInfo on CartItem {
  id
  name
  description
  images
  quantity
  metadata
  unitTotal {
    amount
    formatted
  }
  lineTotal {
    amount
    formatted
  }
  createdAt
  updatedAt
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=query%20%7B%0A%20%20cart(%0A%20%20%20%20id%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20currency%3A%20%7B%0A%20%20%20%20%20%20code%3A%20GBP%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20...CartWithItems%0A%20%20%7D%0A%7D%0A%20%0Afragment%20CartWithItems%20on%20Cart%20%7B%0A%20%20...CartInfo%0A%20%20items%20%7B%0A%20%20%20%20...ItemInfo%0A%20%20%7D%0A%7D%0A%20%0Afragment%20CartInfo%20on%20Cart%20%7B%0A%20%20id%0A%20%20email%0A%20%20isEmpty%0A%20%20abandoned%0A%20%20totalItems%0A%20%20totalUniqueItems%0A%20%20currency%20%7B%0A%20%20%20%20code%0A%20%20%20%20symbol%0A%20%20%7D%0A%20%20subTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20shippingTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20taxTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20grandTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20attributes%20%7B%0A%20%20%20%20key%0A%20%20%20%20value%0A%20%20%7D%0A%20%20notes%0A%20%20createdAt%0A%20%20updatedAt%0A%7D%0A%20%0Afragment%20ItemInfo%20on%20CartItem%20%7B%0A%20%20id%0A%20%20name%0A%20%20description%0A%20%20images%0A%20%20quantity%0A%20%20attributes%20%7B%0A%20%20%20%20key%0A%20%20%20%20value%0A%20%20%7D%0A%20%20unitTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20lineTotal%20%7B%0A%20%20%20%20amount%0A%20%20%20%20formatted%0A%20%20%7D%0A%20%20createdAt%0A%20%20updatedAt%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Add to Cart`}</h3>
    <p>{`Items by default are of type `}<inlineCode parentName="p">{`SKU`}</inlineCode>{`, but you can add `}<inlineCode parentName="p">{`SHIPPING`}</inlineCode>{`, and `}<inlineCode parentName="p">{`TAX`}</inlineCode>{`, which modify the `}<inlineCode parentName="p">{`subTotal`}</inlineCode>{`, `}<inlineCode parentName="p">{`grandTotal`}</inlineCode>{` values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  addItem(
    input: {
      cartId: "ck5r8d5b500003f5o2aif0v2b"
      id: "5e3293a3462051"
      name: "Full Logo Tee"
      description: "Purple Triblend / L"
      images: ["full-logo-tee.png"]
      price: 2000
    }
  ) {
    id
    isEmpty
    abandoned
    totalItems
    totalUniqueItems
    subTotal {
      formatted
    }
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=mutation%20%7B%0A%20%20addItem(%0A%20%20%20%20input%3A%20%7B%0A%20%20%20%20%20%20cartId%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20%20%20id%3A%20%225e3293a3462051%22%2C%0A%20%20%20%20%20%20name%3A%20%22Full%20Logo%20Tee%22%2C%0A%20%20%20%20%20%20description%3A%20%22Purple%20Triblend%20%2F%20L%22%2C%0A%20%20%20%20%20%20images%3A%20%5B%22full-logo-tee.png%22%5D%2C%0A%20%20%20%20%20%20price%3A%202000%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20id%0A%20%20%20%20isEmpty%0A%20%20%20%20abandoned%0A%20%20%20%20totalItems%0A%20%20%20%20totalUniqueItems%0A%20%20%20%20subTotal%20%7B%0A%20%20%20%20%20%20formatted%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Update Cart Item`}</h3>
    <p>{`Update any of the item properties using the `}<inlineCode parentName="p">{`updateItem`}</inlineCode>{` GraphQL mutation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  updateItem(
    input: {
      cartId: "ck5r8d5b500003f5o2aif0v2b"
      id: "5e3293a3462051"
      price: 2500
      quantity: 2
    }
  ) {
    id
    isEmpty
    abandoned
    totalItems
    totalUniqueItems
    subTotal {
      formatted
    }
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=mutation%20%7B%0A%20%20updateItem(%0A%20%20%20%20input%3A%20%7B%0A%20%20%20%20%20%20cartId%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20%20%20id%3A%20%225e3293a3462051%22%2C%0A%20%20%20%20%20%20quantity%3A%202%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20id%0A%20%20%20%20isEmpty%0A%20%20%20%20abandoned%0A%20%20%20%20totalItems%0A%20%20%20%20totalUniqueItems%0A%20%20%20%20subTotal%20%7B%0A%20%20%20%20%20%20formatted%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Remove Cart Item`}</h3>
    <p>{`No longer need a cart item? You can remove it easily.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  removeItem(
    input: { cartId: "ck5r8d5b500003f5o2aif0v2b", id: "5e3293a3462051" }
  ) {
    id
    isEmpty
    abandoned
    totalItems
    totalUniqueItems
    subTotal {
      formatted
    }
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=mutation%20%7B%0A%20%20removeItem(%0A%20%20%20%20input%3A%20%7B%0A%20%20%20%20%20%20cartId%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20%20%20id%3A%20%225e3293a3462051%22%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20id%0A%20%20%20%20isEmpty%0A%20%20%20%20abandoned%0A%20%20%20%20totalItems%0A%20%20%20%20totalUniqueItems%0A%20%20%20%20subTotal%20%7B%0A%20%20%20%20%20%20formatted%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Custom `}<inlineCode parentName="h3">{`metadata`}</inlineCode></h3>
    <p>{`Some items have more than just a `}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<inlineCode parentName="p">{`description`}</inlineCode>{`, and `}<inlineCode parentName="p">{`price`}</inlineCode>{`, but attributes such as engraving, personalized notes, and more.`}</p>
    <p>{`You can use the `}<inlineCode parentName="p">{`metadata`}</inlineCode>{` object to store custom data about items, and the cart itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  updateItem(
    input: {
      cartId: "ck5r8d5b500003f5o2aif0v2b",
      id: "5e3293a3462051",
      metadata: {
        "engraving": "Jamie"
      }
    }
  ) {
    id
    metadata
  }
}
`}</code></pre>
    <h3>{`Currency Formatting`}</h3>
    <p>{`No matter the location of your users, you can format the cart based on their currency.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`updateCart`}</inlineCode>{` GraphQL mutation accepts properties for `}<inlineCode parentName="p">{`currency`}</inlineCode>{`, which include things such as the `}<inlineCode parentName="p">{`code`}</inlineCode>{`, `}<inlineCode parentName="p">{`symbol`}</inlineCode>{`, `}<inlineCode parentName="p">{`thousandsSeparator`}</inlineCode>{`, and more.`}</p>
    <p>{`CartQL will automatically figure out the symbol, separator, and more, based on the `}<inlineCode parentName="p">{`code`}</inlineCode>{` you give it.`}</p>
    <p>{`Try swapping out `}<inlineCode parentName="p">{`GBP`}</inlineCode>{` in the example below with `}<inlineCode parentName="p">{`EUR`}</inlineCode>{`, `}<inlineCode parentName="p">{`TRY`}</inlineCode>{`, or `}<inlineCode parentName="p">{`USD`}</inlineCode>{`, and see all money meta in the cart change.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  updateCart(
    input: { id: "ck5r8d5b500003f5o2aif0v2b", currency: { code: GBP } }
  ) {
    id
    currency {
      code
      symbol
      thousandsSeparator
      decimalSeparator
      decimalDigits
    }
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=mutation%20%7B%0A%20%20updateCart(%0A%20%20%20%20input%3A%20%7B%0A%20%20%20%20%20%20id%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20%20%20currency%3A%20%7B%0A%20%20%20%20%20%20%20%20code%3A%20GBP%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20id%0A%20%20%20%20currency%20%7B%0A%20%20%20%20%20%20code%0A%20%20%20%20%20%20symbol%0A%20%20%20%20%20%20thousandsSeparator%0A%20%20%20%20%20%20decimalSeparator%0A%20%20%20%20%20%20decimalDigits%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Checkout`}</h3>
    <p>{`Are you ready to checkout your cart?`}</p>
    <p>{`CartQL provides a `}<inlineCode parentName="p">{`checkout`}</inlineCode>{` mutation that you can use on the frontend to help capture customer addresses, emails, and notes.`}</p>
    <p>{`Quite often you will want to control the checkout flow, and the `}<inlineCode parentName="p">{`checkout`}</inlineCode>{` mutation lets you capture the shipping, and billing address of your customer, and returns an immutable cart as an "Order".`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`mutation {
  checkout(
    input: {
      cartId: "ck5r8d5b500003f5o2aif0v2b"
      email: "jamie@cartql.com"
      shipping: {
        name: "Jamie Barton"
        line1: "123 Cart Lane"
        city: "Newcastle upon Tyne"
        postalCode: "NE14 CQL"
        country: "England"
      }
      billing: {
        # Optional
        name: "Jamie Barton"
        line1: "123 Cart Lane"
        city: "Newcastle upon Tyne"
        postalCode: "NE14 CQL"
        country: "England"
      }
    }
  ) {
    id
    grandTotal {
      formatted
    }
  }
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://api.cartql.com/?query=mutation%20%7B%0A%20%20checkout(%0A%20%20%20%20input%3A%20%7B%0A%20%20%20%20%20%20cartId%3A%20%22ck5r8d5b500003f5o2aif0v2b%22%2C%0A%20%20%20%20%20%20email%3A%20%22jamie%40cartql.com%22%2C%0A%20%20%20%20%20%20shipping%3A%20%7B%0A%20%20%20%20%20%20%20%20name%3A%20%22Jamie%20Barton%22%2C%0A%20%20%20%20%20%20%20%20line1%3A%20%22123%20Cart%20Lane%22%2C%0A%20%20%20%20%20%20%20%20city%3A%20%22Newcastle%20upon%20Tyne%22%2C%0A%20%20%20%20%20%20%20%20postalCode%3A%20%22NE14%20CQL%22%2C%0A%20%20%20%20%20%20%20%20country%3A%20%22England%22%0A%20%20%20%20%20%20%7D%2C%0A%20%20%20%20%20%20billing%3A%20%7B%20%23%20Optional%0A%20%20%20%20%20%20%20%20name%3A%20%22Jamie%20Barton%22%2C%0A%20%20%20%20%20%20%20%20line1%3A%20%22123%20Cart%20Lane%22%2C%0A%20%20%20%20%20%20%20%20city%3A%20%22Newcastle%20upon%20Tyne%22%2C%0A%20%20%20%20%20%20%20%20postalCode%3A%20%22NE14%20CQL%22%2C%0A%20%20%20%20%20%20%20%20country%3A%20%22England%22%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20)%20%7B%0A%20%20%20%20id%0A%20%20%20%20grandTotal%20%7B%0A%20%20%20%20%20%20formatted%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D"
      }}>{`Open in GraphQL Playground`}</a></p>
    <h3>{`Checkout with Stripe`}</h3>
    <p>{`Instead of building your own checkout, you can opt to use something like `}<a parentName="p" {...{
        "href": ""
      }}>{`Stripe Checkout`}</a>{`.`}</p>
    <p>{`Together with serverless functions, you can fetch items from your cart, and create a Stripe checkout session.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const Stripe = require("stripe");
const { request, gql } = require("graphql-request");

const stripe = new Stripe(process.env.STRIPE_SECRET_KEY);

const query = gql\`
  query getCart($cartId: ID!) {
    cart(id: $cartId) {
      id
      isEmpty
      items {
        id
        name
        description
        unitTotal {
          amount
          currency {
            code
          }
        }
        quantity
      }
    }
  }
\`;

exports.handler = async function (event) {
  const { cartId } = JSON.parse(event.body);

  const {
    cart: { isEmpty, items },
  } = await request(process.env.GATSBY_GRAPHQL_ENDPOINT, query, {
    cartId,
  });

  if (isEmpty) {
    return {
      statusCode: 400,
      body: JSON.stringify({ message: "The cart is empty." }),
    };
  }

  try {
    const session = await stripe.checkout.sessions.create({
      mode: "payment",
      payment_method_types: ["card"],
      success_url: \`\${process.env.URL}/thankyou\`,
      cancel_url: \`\${process.env.URL}/cart\`,
      line_items: items.map(
        ({
          name,
          description,
          unitTotal: {
            amount: unit_amount,
            currency: { code: currency },
          },
          quantity,
        }) => ({
          ...(description && { description }),
          price_data: {
            currency,
            unit_amount,
            product_data: {
              name,
              ...(description && { description }),
            },
          },
          quantity,
        })
      ),
    });

    return {
      statusCode: 201,
      body: JSON.stringify(session),
    };
  } catch ({ message }) {
    return {
      statusCode: 401,
      body: JSON.stringify({ message }),
    };
  }
};
`}</code></pre>
    <h3>{`Lots more!`}</h3>
    <p>{`There's mutations to set all items in the cart with `}<inlineCode parentName="p">{`setItems`}</inlineCode>{`, increment, or decrement just the quantity of items with `}<inlineCode parentName="p">{`incrementItemQuantity`}</inlineCode>{`, and `}<inlineCode parentName="p">{`decrementItemQuantity`}</inlineCode>{`. You can also empty the cart with `}<inlineCode parentName="p">{`emptyCart`}</inlineCode>{`, or delete it with `}<inlineCode parentName="p">{`deleteCart`}</inlineCode>{`.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/CartQL/gatsby-cartql-starter"
      }}>{`gatsby-cartql-starter`}</a>{` is a great place to get going! Built with Apollo Client 3, and a filesystem based inventory. 🚀`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      